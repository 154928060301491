import React from "react";
import { useInView } from "react-intersection-observer";
import {
  PageContainer,
  StripContainer,
  Title,
  CardsContainer,
  Card,
  CardTitle,
  CardText,
} from "./style";
import plug_play from "../../../resources/images/plug_play.png";
import data_privacy from "../../../resources/images/data_privacy.png";
import real_time from "../../../resources/images/real_time.png";

const WhyCrowdflow = () => {
  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: false,
    threshold: 0,
  });
  const { ref: ref2, inView: inView2 } = useInView({
    triggerOnce: false,
    threshold: 0,
  });
  const { ref: ref3, inView: inView3 } = useInView({
    triggerOnce: false,
    threshold: 0,
  });

  return (
    <PageContainer>
      <StripContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 80">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M1200 0L0 0 1000 50 1200 0z"
          ></path>
        </svg>
        <Title>Why CrowdFlow</Title>
        <CardsContainer>
          <Card
            ref={ref1}
            inView={inView1}
            direction="left"
            backgroundImage={plug_play}
          >
            <CardTitle>Plug & Play</CardTitle>
            <CardText>
              Easy to integrate over existing surveilance systems.
            </CardText>
          </Card>
          <Card
            ref={ref2}
            inView={inView2}
            direction="left"
            backgroundImage={data_privacy}
          >
            <CardTitle>Data Privacy</CardTitle>
            <CardText>
              Costumer privacy is our number one priority. As such, CrowdFlow is
              GDPR compliant.
            </CardText>
          </Card>
          <Card
            ref={ref3}
            inView={inView3}
            direction="left"
            backgroundImage={real_time}
          >
            <CardTitle>Real-Time</CardTitle>
            <CardText>
              Monitor and analyze data as it happens, allowing you to make
              informed decisions on the fly.
            </CardText>
          </Card>
        </CardsContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0 120L1200 120 250 90 0 120z"
          ></path>
        </svg>
      </StripContainer>
    </PageContainer>
  );
};

export default WhyCrowdflow;
