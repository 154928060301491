import React, { forwardRef } from "react";
import {
  PageContainer,
  Title,
  Subtitle,
  StripContainer,
  ImagesContainer,
  ImageNameContainer,
  Image,
  LinkedInIcon,
  Name,
  BottomText
} from "./style";

const TeamMember = ({ src, name, linkedInUrl}) => {
  return (
    <ImageNameContainer >
      <Image loading="lazy" src={`https://ik.imagekit.io/crowdflow/${src}.png`}/>
      <LinkedInIcon onClick={() => window.open(linkedInUrl, "_blank")} />
      <Name>{name}</Name>
    </ImageNameContainer>
  );
};

// Página de introducao da equipa
const AboutUs = forwardRef((props, ref) => {

  const teamMembersData = [
    {
      src: "profile_lucas",
      name: "Duarte Lucas",
      linkedInUrl: "https://www.linkedin.com/in/duarte-lucas75/",
    },
    {
      src: "profile_izquierdo",
      name: "Francisco Izquierdo",
      linkedInUrl: "https://www.linkedin.com/in/francisco-izquierdo-a45856242/",
    },
    {
      src: "profile_novo",
      name: "Francisco Novo",
      linkedInUrl: "https://www.linkedin.com/in/francisco-novo-471741293/",
    },
    {
      src: "profile_vieira",
      name: "João Vieira",
      linkedInUrl: "https://www.linkedin.com/in/jo%C3%A3o-vieira-07b6a0299/",
    },
    {
      src: "profile_magalhaes",
      name: "José Magalhães",
      linkedInUrl: "https://www.linkedin.com/in/jos%C3%A9-pedro-magalh%C3%A3es-88837a256/",
    },
    {
      src: "profile_ribeiro",
      name: "Tiago Ribeiro",
      linkedInUrl: "https://www.linkedin.com/in/tiago-ribeiro-265257270/",
    },
  ];

  return (
    <PageContainer ref={ref}>
      <Title>About us</Title>
      <Subtitle>CrowdFlow was born driven by a shared vision: to revolutionize how businesses understand customer behavior in physical stores.</Subtitle>
      <StripContainer>
        <ImagesContainer>
          {teamMembersData.map((member) => (
            <TeamMember
              key={member.name}
              {...member}
            />
          ))}
        </ImagesContainer>
        <BottomText>Our team can help you take your business to new heights by optimizing sales and revenue for physical stores.</BottomText>
      </StripContainer>
    </PageContainer>
  );
});

export default AboutUs;
